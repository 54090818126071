
import { defineComponent, ref, onMounted, onUnmounted } from "vue"
import { userStore } from "@/stores/userStore"
import { useRouter } from "vue-router"
import { fangankefu, shouhoukefu, shouqiankefu } from "@/utils/alert"

export default defineComponent({
	name: "FixedLeft",
	components: {},
	setup() {
		let router = useRouter()
		let id = userStore().id
		let goToUserCenter = () => {
			router.push({ name: "suggest" })
		}
		let goToTop = () => {
			window.scrollTo(0, 0)
		}
		const showBackToTop = ref(false) // 控制回到顶部按钮显示与隐藏的变量
		const handleScroll = () => {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop
			const firstScreenHeight = window.innerHeight
			// 判断滚动位置是否超过首屏高度，更新showBackToTop的值
			showBackToTop.value = scrollTop > firstScreenHeight
		}
		onMounted(() => {
			window.addEventListener("scroll", handleScroll) // 添加滚动事件监听器
		})
		onUnmounted(() => {
			window.removeEventListener("scroll", handleScroll) // 移除滚动事件监听器
		})
		return {
			goToTop,
			goToUserCenter,
			id,
			fangankefu,
			shouhoukefu,
			shouqiankefu,
			showBackToTop,
		}
	},
})
