
import {
	defineComponent,
	onMounted,
	reactive,
	ref,
	computed,
	inject,
	watch,
	nextTick,
	onUnmounted,
} from "vue"
import { useRoute, useRouter } from "vue-router"
import { sjyAlert } from "@/utils/alert"

import CurrentAddress from "@/components/common/CurrentAddress.vue"
import HomeHead from "@/components/HomeHead.vue"
import HtmlFooter from "@/components/HtmlFooter.vue"
import GoodsSwiper from "@/components/goods/GoodsSwiper.vue"
import GoodsCollection from "@/components/goods/GoodsCollection.vue"
import Consultion from "@/components/goods/Consultion.vue"
import HatGoodsInfo from "@/components/goods/project/HatGoodsInfo.vue"
import DetailInfo from "@/components/goods/project/detail/DetailInfo.vue"
import VideoInfo from "@/components/goods/project/detail/VideoInfo.vue"
import StructureInfo from "../../components/goods/project/detail/StructureInfo.vue"
import DownInfo from "@/components/goods/project/detail/DownInfo.vue"
import ImgInfo from "@/components/goods/project/detail/ImgInfo.vue"

import { goodsApi } from "@/service/api"
import { getVipInfo, updateCartTotal, showPrice } from "@/utils/public"
import GoodsTemp from "@/components/goods/GoodsTemp.vue"
import VipList from "@/components/goods/detail/VipList.vue"
import GoodsClass from "@/components/goods/detail/GoodsClass.vue"

import { useHead } from "@vueuse/head"
import { userStore } from "@/stores/userStore"
import { shouhoukefu, shouqiankefu } from "@/utils/alert"

export default defineComponent({
	components: {
		CurrentAddress,
		HomeHead,
		HtmlFooter,
		GoodsSwiper,
		GoodsCollection,
		Consultion,
		HatGoodsInfo,
		DetailInfo,
		VideoInfo,
		StructureInfo,
		DownInfo,
		ImgInfo,
		GoodsTemp,
		VipList,
		GoodsClass,
	},
	// const route = useRoute()
	//       console.log(route.params)
	setup() {
		let id = ref(0)

		let goodsNum = ref(1)
		let servicePice = inject("servicePice", 25)
		interface goodsType {
			errno: number
			datas: any
			errmsg: string
		}
		let router = useRouter()
		//let route = useRoute()

		let goodsInfo: goodsType = reactive({
			errno: 0,
			datas: {},
			errmsg: "",
		})
		let route = useRoute()
		watch(route, () => {
			window.scrollTo(0, 0)
			router.go(0)
		})
		//产品详细页， 选中状态
		let active = ref(0)
		let toActive = (val: number) => {
			active.value = val
		}
		let vipinfo = getVipInfo()
		let userinfo = userStore()
		//获取参数
		let DownUrl = ref()
		id.value = parseInt(route.params.id as string)
		//获取产品的详细信息
		let getGoodsInfo = () => {
			goodsApi.getGoodsInfo(id.value).then((res: any) => {
				goodsInfo.datas = res.datas
				DownUrl.value = res.datas.files_url
				if (goodsInfo.datas.is_real_goods == 1) {
					if (goodsInfo.datas.minimum - goodsInfo.datas.stock > 0) {
						goodsNum.value = 0
					}
					userinfo.cartType = 1
				}
				if (userinfo.id > 0) {
					let tarckType = 1
					if (goodsInfo.datas.is_real_goods == 1) {
						tarckType = 4
					}
					goodsApi
						.addGoodsTrack(goodsInfo.datas.id, tarckType, 1)
						.then((res: any) => {
							//console.log(res);
						})
				}

				goodsApi
					.goodsBought(userinfo.id, goodsInfo.datas.id)
					.then((res: any) => {
						if (res.errno == 0 && res.buy == 1) {
							goodsInfo.datas.buy = 1
							goodsInfo.datas.orderId = res.orderId
						} else {
							goodsInfo.datas.buy = 0
						}
					})
				getGoodsList(goodsInfo.datas.cid)
				siteData.title = goodsInfo.datas.names
				siteData.keywords =
					"设纪元为您提供（产品类别）等产品的相关信息，欢迎您通过设纪元购买图纸、模型、设计方案。"
				siteData.description =
					"模型下载_末端执行器图纸下载_自动化设计_自动化毕业设计_工业设计资料_工业设计方案_参数化设计_机器人方案_非标自动化设计"
			})
		}
		let entryTime = Date.now()
		const addDuration = () => {
			const leaveTime = Date.now()
			const duration = leaveTime - entryTime
			let tarckType = 1
			if (goodsInfo.datas.is_real_goods == 1) {
				tarckType = 4
			}
			localStorage.setItem(
				"duration",
				goodsInfo.datas.id + "_" + tarckType + "_" + duration
			)

			goodsApi
				.addGoodsDuration(goodsInfo.datas.id, tarckType, duration)
				.then((res: any) => {
					localStorage.removeItem("duration")
				})
		}
		onUnmounted(() => {
			addDuration()
		})
		addEventListener("beforeunload", addDuration)

		let showSeo = () => {
			useHead({
				title: () => siteData.title,
				meta: [
					{
						name: `description`,
						content: () => siteData.description,
					},
					{
						name: `keywords`,
						content: () => siteData.keywords,
					},
				],
			})
		}
		let hotgoodslite = reactive({
			datas: {
				data: [],
				pageSize: 0,
				total: 0,
			},
		})
		//获取商品列表
		let getGoodsList = (cid: any) => {
			goodsApi.getHotGoodsList({ cid }).then((res: any) => {
				if (res.errno == 0) {
					hotgoodslite.datas = res.datas
				}
			})
		}
		let staticUrl = inject("staticUrl", "")
		const videoUrl = computed(() => {
			//let modleName = goodsInfo.datas.names.split("：")[1]
			return staticUrl + modleName + ".mp4"
		})

		const videoImgUrl = computed(() => {
			//let modleName = goodsInfo.datas.names.split("：")[1]
			return staticUrl + modleName + ".jpg"
		})

		//加入购物车
		let addCart = () => {
			goodsApi
				.addCart(
					id.value,
					serviceStatus.value,
					goodsInfo.datas.is_real_goods,
					goodsNum.value
				)
				.then((res: any) => {
					if (res.errno == 0) {
						updateCartTotal()
						sjyAlert("添加成功", "success")
					} else if (res.errno != 70010) {
						sjyAlert(res.errmsg, "error")
					}
				})
		}

		//判断是否是折扣
		let isActivity = () => {
			if (
				goodsInfo.datas.activity_price &&
				goodsInfo.datas.activity_price > 0
			) {
				return true
			} else {
				return false
			}
		}
		let getImgUrl = inject(
			"getImgUrl",
			(index: string) => {
				return ""
			},
			false
		)

		const modleName = computed(() => {
			if (goodsInfo.datas.model && goodsInfo.datas.model != "") {
				return goodsInfo.datas.model
			} else {
				return goodsInfo.datas.names.split("：")[1]
			}
		})
		let serviceStatus = ref(false)
		let changStatus = (val: boolean) => {
			serviceStatus.value = val
		}
		// let staticUrl = inject("staticUrl")
		let resetImg = () => {
			let tmepImg = []
			let tmepImgList = []
			if (goodsInfo.datas.pics && goodsInfo.datas.pics.length > 0) {
				if (goodsInfo.datas.duration && goodsInfo.datas.duration > 0) {
					tmepImg.push(require("@/assets/common/images/default.jpg"))
					let modleName = ""
					if (goodsInfo.datas.model && goodsInfo.datas.model != "") {
						modleName = goodsInfo.datas.model
					} else {
						modleName = goodsInfo.datas.names.split("：")[1]
					}

					tmepImgList.push(
						staticUrl + "/filespath/files/video/" + modleName + ".mp4"
					)
				}

				for (let i = 0; i < goodsInfo.datas.pics.length; i++) {
					tmepImg.push(getImgUrl(goodsInfo.datas.pics[i]))
					tmepImgList.push(getImgUrl(goodsInfo.datas.pics[i]))
				}

				//console.log(tmepImg,tmepImgList,'resetIm')
			}

			return { tmepImg, tmepImgList }
		}
		let buyNow = () => {
			if (userinfo.id > 0) {
				let routeData = router.resolve({
					path:
						"/confirmed-order/" +
						id.value +
						"-" +
						(serviceStatus.value ? 1 : 0) +
						"-" +
						goodsNum.value,
				})
				window.open(routeData.href, "_blank")
			} else {
				sjyAlert("请先登录", "error", () => {
					const userCommonStore = userStore()
					userCommonStore.deleteUserInfo()
					router.push({ name: "login" })
				})
			}
		}

		let position = reactive({
			x: 0,
			y: 0,
		})

		let fisOutside = ref(true)
		let nowImgUrl = ref("")

		let setPosition = (
			x: number,
			y: number,
			isOutside: boolean,
			activeIndex: number = 0
		) => {
			position.x = x
			position.y = y
			fisOutside.value = isOutside
			let imgList = resetImg()
			nowImgUrl.value = imgList.tmepImg[activeIndex]
		}
		const siteData = reactive({
			title: route.meta.title as string,
			description: route.meta.description as string,
			keywords: route.meta.keywords as string,
		})

		let gotoUser = (type: string = "") => {
			router.push({
				name: "myvip",
				params: {
					type: type,
				},
			})
		}
		let buyDown = () => {
			router.push({
				name: "orderdown",
				params: {
					id: goodsInfo.datas.orderId,
				},
			})
		}

		onMounted(() => {
			getGoodsInfo()
			showSeo()
			window.scrollTo(0, 0)
		})
		return {
			modleName,
			servicePice,
			active,
			toActive,
			goodsInfo,
			addCart,
			changStatus,
			resetImg,
			buyNow,
			position,
			setPosition,
			fisOutside,
			nowImgUrl,
			vipinfo,
			gotoUser,
			hotgoodslite,
			buyDown,
			isActivity,
			goodsNum,
			showPrice,
			shouhoukefu,
			shouqiankefu,
			DownUrl,
		}
	},
})
