
import { defineComponent } from "vue"
import { userStore } from "@/stores/userStore"
import { useRouter } from "vue-router"
import { fangankefu, shouhoukefu, shouqiankefu } from "@/utils/alert"

export default defineComponent({
	name: "FixedLeft",
	components: {},
	setup() {
		let router = useRouter()
		let id = userStore().id
		let goToUserCenter = () => {
			router.push({ name: "suggest" })
		}
		let goToTop = () => {
			window.scrollTo(0, 0)
		}

		return {
			goToTop,
			goToUserCenter,
			id,
			fangankefu,
			shouhoukefu,
			shouqiankefu,
		}
	},
})
